import React, { useState } from 'react';
import styles from './still-hundry-menu.module.scss';

const StillHungryMenu = ({ menus, setActive }) => {
  const [activeMenu, setActiveMenu] = useState(null);

  const changeActiveMenu = (active, e) => {
    setActive(menus[active]);
    setActiveMenu(active);
    e.preventDefault();
  };

  return (
    <div
      className={styles.stillHungryMenuContainer} data-active-state={activeMenu + ''}>
      <h2 className={styles.stillHungryMenuTitle}>A LA CARTE MENU</h2>
      <ul className={styles.stillHungryMenuList}>
        {menus.map((item, index) => {
          return (
            <li className={(activeMenu === index || (index === 0 && activeMenu === null)) && styles.active}
              key={index}>
              <button onClick={(e) => {changeActiveMenu(index, e)}} >
                {item}
              </button>
            </li>
          );
        })}
      </ul>
        {activeMenu === null && <div className={styles.stillHungryMenuImage}></div>}
      <button onClick={() => {setActive(null); setActiveMenu(null)}}
        className={styles.stillHungryMenuActiveItem}
      >
        {menus[activeMenu]}
      </button>
    </div>
  );
};

export default StillHungryMenu;
