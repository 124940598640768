// Libs.
import React from 'react';
// Components.
import Layout from '../../components/layout/layout';
import SEO from '../../components/layout/seo';
import StillHungry from '../../components/common/still-hungry/still-hungry';
import { useStateValue } from '../../store/state';
import getWindow from '../../libs/getWindow';
import { graphql, navigate } from 'gatsby';
import pages from '../../data/pageAliases';
import { isEmpty, get } from 'lodash';

const AddToYourOrder = ({ data, location }) => {
  const [state] = useStateValue();
  const cart = state.cart.fmk;
  let isDisabled = cart.lineItems.find(item => item.sides.length > 0);
  console.log(isDisabled);
  if (isEmpty(isDisabled) && !!getWindow('location.hostname')) {
    navigate(pages.festiveMealKits);
  }

  return (
    <Layout>
      <SEO title="Still Hungry"/>
      {cart.lineItemsCount && <StillHungry data={data.hero} checkout={get(location, 'state.extra', false)}/>}
    </Layout>
  );
};

export default AddToYourOrder;

export const query = graphql`
  query {
    hero: configPagesStillHungry {
      title: field_label
      description: field_description {
        value
      }
      link: field_link {
        uri
        title
      }
    }
  }
`;
