import React from 'react';
import styles from './still-hungry-hero.module.scss';
import SiteLink from '../site-link';
import pageAliases from '../../../data/pageAliases';

const StillHungryHero = ({ data }) => {
  const {checkout} = data;
  return (
      <div className={styles.stillHungryHeroContainer}>
        <h1 className={styles.stillHungryHeroTitle}>{data.title}</h1>
        <p className={styles.stillHungryHeroText} dangerouslySetInnerHTML={{__html: data.description.value}} />
        <SiteLink className={styles.stillHungryHeroButton} href={checkout ?   pageAliases.festiveMealKitsConfirmOrder : data.link.uri}>
          {data.link.title}
        </SiteLink>
      </div>
  );
};

export default StillHungryHero;
