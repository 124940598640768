// Libs
import React, { useState } from 'react';
import useForm from 'react-hook-form';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {get} from 'lodash';

//Assets.
import styles from './card-add-on.module.scss';
import plusIcon from '../../../images/icons/icon-plus.svg';
import minusIcon from '../../../images/icons/icon-minus.svg';
import successIcon from '../../../images/icons/icon-cart-success.svg';
import { useStateValue } from '../../../store/state';
import { AddDish } from '../../../store/cart/action-creators';
import locale from '../../../locale/locale';

const CardAddOn = ({ cardData, variation = 'default' }) => {
  const [state, dispatch] = useStateValue();
  const fmk = state.cart.fmk.lineItems;
  const cartItem = fmk.find(item => {
    return item.itemId === cardData.crmId;
  });
  const [count, setCount] = useState(cartItem ? parseInt(cartItem.quantity) : 1);
  const { register, errors, handleSubmit, setValue } = useForm();
  const [submission, setSubmission] = useState(cartItem);
  const Decrease = (data) => {
    if (data.quantity && data.quantity - 1) {
      setCount(count - 1);
      setValue('quantity', count - 1);
      setSubmission(false)
    }
  };
  const Increase = (data) => {
    if (data.quantity && parseInt(data.quantity) + 1 <= 99) {
      setCount(count + 1);
      setValue('quantity', count + 1);
      setSubmission(false)
    }
  };
  const Change = (data) => {
    const quantity = parseInt(data.quantity);
    if (data.quantity && quantity <= 99 && quantity > 0) {
      setCount(quantity);
      setSubmission(false)
    }
  };

  const successMessage = 'Added to Cart!';
  const onSubmit = (data) => {
    data.itemId = cardData.crmId;
    dispatch(AddDish({ itemId: cardData.crmId, quantity: count}));
    setSubmission(true);
  };

  return (
    <div className={styles.cardAddOn}>
      <form className={styles.cardAddOnCard} onSubmit={handleSubmit(onSubmit)}>
        {cardData.image && <Img className={styles.cardAddOnImage} fluid={cardData.image.fluid}/>}
        {cardData.title && <h3 className={styles.cardAddOnTitle}>{cardData.title}</h3>}
        <div className={styles.cardAddOnProductDetails}>
          {cardData.description && (
            <div className={styles.cardAddOnSize}>{cardData.description} | </div>
          )}
          {cardData.price && <div className={styles.cardAddOnPrice}>${cardData.price}</div>}
        </div>
        <div className={styles.cardAddOnQuantityWrap}>
          <button
            className={styles.cardAddOnButton}
            onClick={handleSubmit(Decrease)}
            ref={register()}
          >
            <img src={minusIcon} alt="Decrease"/>
          </button>
          <input
            className={styles.cardAddOnQuantity}
            type={'number'}
            max={99}
            defaultValue={count}
            ref={register({ pattern: { value: /^\d+$/, message: locale.fmkCard.dishError }, min: { value: 1, message: locale.fmkCard.dishError }, max: { value: 99, message: locale.fmkCard.dishError } })}
            name={'quantity'}
            data-error={!!errors['quantity']}
            onChange={handleSubmit(Change)}
            onBlur={handleSubmit(Change)}
          />
          <button
            className={styles.cardAddOnButton}
            onClick={handleSubmit(Increase)}
            ref={register()}
          >
            <img src={plusIcon} alt="Increase"/>
          </button>
        </div>
        <div className={styles.formInputError} data-error={!!errors['quantity']}>
          {errors['quantity'] && <span>{errors['quantity'].message}</span>}
        </div>
        {!submission && (
          <input type={'submit'} value={get(cartItem, 'quantity', 0) ? 'Update Cart' : 'Add to Cart'} className={styles.cardAddOnSubmit}/>
        )}
        {submission && (
          <div className={styles.cardAddOnSuccessMessage}>
            <img src={successIcon} alt="Success"/>
            {successMessage}
          </div>
        )}
      </form>
    </div>
  );
};

CardAddOn.defaultProps = {
  cardData: {
    image: null,
    title: null,
    description: null,
    price: null,
    link: null,
  },
};

CardAddOn.propTypes = {
  cardData: {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    link: {
      title: PropTypes.string.isRequired,
      uri: PropTypes.object.isRequired,
    },
  },
};

export default CardAddOn;
