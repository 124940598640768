// Libs
import React, { useState } from 'react';
import { get, uniqBy } from 'lodash';
// State
import { useDishesIndex } from '../../../services/dishesIndexService';
// Deps
import StillHungryHero from './still-hungry-hero';
import StillHungryMenu from '../../still-hungry-menu/still-hundry-menu';
import CardAddOn from '../cards/card-add-on';
import Tiles from '../tiles/tiles';
// Assets
import styles from './still-hungry.module.scss';

const StillHungry = ({ data, checkout }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const dishes = useDishesIndex().getAll();

  let filteredDishes = dishes;
  let types = uniqBy(dishes, 'type.name');
  types = types.map(item => {
    return get(item, 'type.name');
  });

  filteredDishes = filteredDishes.filter(item => {
    let activeItem = activeMenu;
    if (activeMenu === null) {
      activeItem = types[0];
    }
    return (item.type.name === activeItem);
  });

  return (
    <div className={styles.stillHungry}>
      <StillHungryHero data={{...data, ...{checkout: checkout}}}/>
      <div className={styles.stillHungryTitle}>ADD-ON TO COMPLETE YOUR ORDER</div>
      <div className={styles.stillHungryWrapper} data-active={(activeMenu + '')}>
        <div className={styles.stillHungryContainer}>
          <StillHungryMenu menus={types} setActive={setActiveMenu}/>
          <Tiles columns={2} className={styles.stillHungryDishTiles}>
            {filteredDishes.map((dish, index) => (
              <CardAddOn cardData={dish} key={index + activeMenu}/>
            ))}
          </Tiles>
        </div>
      </div>
    </div>
  );
};

export default StillHungry;
